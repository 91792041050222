import React, { useState, useEffect, useRef, useCallback } from "react";
import { config } from "./Components/Config";
import Spinner from "react-bootstrap/Spinner";
import Webcam from "react-webcam";
import moment from "moment";

const Home = () => {
  const apiUrl = config.APIURL;
  const triggerUrl = config.TRIGGERURL;
  const triggerAuth = config.TRIGGERAUTH;
  const bucketName = config.BUCKETNAME;
  const bucketFolder = config.BUCKETFOLDER;
  const bucketMerge = config.BUCKETMERGE;

  const [ customerList, setCustomerList ] = useState([]);
  const [ transactionList, setTransactionList ] = useState([]);
  const [ visitList, setVisitList ] = useState([]);
  const [ selectedDate, setSelectedDate ] = useState(moment().format('YYYY-MM-DD'));
  const [ selectedSorting, setSelectedSorting ] = useState('Date');
  const [ activeCustomer, setActiveCustomer ] = useState({});
  const [ activeMenu, setActiveMenu ] = useState('Visit');
  const [ showDetailCustomer, setShowDetailCustomer ] = useState(false);
  const [ showAddCustomer, setShowAddCustomer ] = useState(false);
  const [ showEditCustomer, setShowEditCustomer ] = useState(false);
  const [ showCamera, setShowCamera ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  
  const [ addName, setAddName ] = useState('');
  const [ addPhone, setAddPhone ] = useState('');
  const [ addEmail, setAddEmail ] = useState('');
  const [ addGender, setAddGender ] = useState('');
  const [ addDob, setAddDob ] = useState('');
  const [ addAddress, setAddAddress ] = useState('');
  const [ addPhoto, setAddPhoto ] = useState(null);
  
  const [ editId, setEditId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editPhone, setEditPhone ] = useState('');
  const [ editEmail, setEditEmail ] = useState('');
  const [ editGender, setEditGender ] = useState('');
  const [ editDob, setEditDob ] = useState('');
  const [ editAddress, setEditAddress ] = useState('');
  const [ editPhoto, setEditPhoto ] = useState(null);

  const dateRef = useRef(selectedDate);
  const sortingRef = useRef(selectedSorting);
  const addPhotoRef = useRef(null);
  const editPhotoRef = useRef(null);
  const webcamRef = useRef(null);
  
  const capture = useCallback((type) => {
    const atob = require('atob');
    const image = webcamRef.current.getScreenshot({width: 720, height: 720});
    const base64 = image.replace(/^data:image\/\w+;base64,/, '');
    const binary = atob(base64);
    const uint8 = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      uint8[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([uint8]);
    if (type === 'Add') {
      setAddPhoto(blob);
    } else {
      setEditPhoto(blob);
    }
    setShowCamera(false);
  }, [webcamRef]);
  
  function currencyFormat(number) {
    number = parseInt(number);
    return 'Rp ' + number.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  
  function randomString() {
    const chars = '1234567890abcdefghijklmnopqrstuvwxyz';
    const digits = 16;
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };  
      reader.onerror = () => {
        reject('Error reading file');
      };  
      reader.readAsDataURL(file);
    });
  }

  useEffect(() => {
    getVisitList();
    getCustomerList();
    
    setInterval(async () => {
      getVisitList();
    }, 5000);
  }, []);

  useEffect(() => {
    dateRef.current = selectedDate;
    sortingRef.current = selectedSorting;

    getVisitList();
    setShowDetailCustomer(false);
  }, [selectedDate, selectedSorting]);

  const getVisitList = async () => {
    const fetchVisitList = await fetch(apiUrl + 'list/visit', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        date: dateRef.current
      })
    });
    const dataVisitList = await fetchVisitList.json();
    if (dataVisitList.status === 200) {
      let visitors = [];
      if (sortingRef.current === "Name") {
        dataVisitList.values.sort((a, b) => a.name.localeCompare(b.name)).map(visitor =>
          visitors.push(visitor)
        );
      } else {
        dataVisitList.values.map(visitor =>
          visitors.push(visitor)
        );
      }
      setVisitList(visitors);
    }
  }

  const getCustomerList = async () => {
    const fetchCustomerList = await fetch(apiUrl + 'list/customer', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    });
    const dataCustomerList = await fetchCustomerList.json();
    if (dataCustomerList.status === 200) {
      setCustomerList(dataCustomerList.values);
    }
  }  
  
  const getTransactionList = async (customerId) => {
    setTransactionList([]);
    const fetchTransactionList = await fetch(apiUrl + 'list/transaction', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        customer_id: customerId
      })
    });
    const dataTransactionList = await fetchTransactionList.json();
    if (dataTransactionList.status === 200) {
      setTransactionList(dataTransactionList.values);
    }
  }


  const getCustomer = async (customerId) => {
    setActiveCustomer({});
    const fetchActiveCustomer = await fetch(apiUrl + 'get/customer', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        customer_id: customerId
      })
    });
    const dataActiveCustomer = await fetchActiveCustomer.json();
    if (dataActiveCustomer.status === 200) {
      setActiveCustomer(dataActiveCustomer.values);
    }
  }

  const addCustomer = async () => {
    setIsLoading(true);
    let data = {
      name: addName,
      phone: addPhone,
      email: addEmail,
      gender: addGender,
      dob: addDob,
      address: addAddress
    };
    const fetchAddCustomer = await fetch(apiUrl + 'add/customer', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify(data)
    });
    const dataAddCustomer = await fetchAddCustomer.json();
    if (dataAddCustomer.status === 200) {
      const addId = dataAddCustomer.values.insertId;
      if (addPhoto) {
        const filePhoto = await toBase64(addPhoto);
        const fileName = randomString() + '.' + (addPhoto.name ? addPhoto.name.split('.').pop() : 'jpg');
        await uploadPhoto(filePhoto, fileName);
        const dataTrigger = await sendTrigger('Add', fileName);
        if (dataTrigger.error === false) {
          sendTrigger('Merge', fileName);
          await editCustomerPhoto(addId, fileName);
          window.location.reload();
        } else {
          await deleteCustomer(addId);
          setAddPhoto(null);
          setIsLoading(false);
          alert('Cannot detect face. Please try another photo.');
        }
      } else {
        window.location.reload();
      }
    } else {
      console.log('Error adding Customer');
    }
  }

  const editCustomer = async () => {
    setIsLoading(true);
    let data = {
      id: editId,
      name: editName,
      phone: editPhone,
      email: editEmail,
      gender: editGender,
      dob: editDob,
      address: editAddress
    };
    const fetchEditCustomer = await fetch(apiUrl + 'edit/customer', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify(data)
    });
    const dataEditCustomer = await fetchEditCustomer.json();
    if (dataEditCustomer.status === 200) {
      if (editPhoto === null) {
        if (activeCustomer.photo) {
          await sendTrigger('Delete', activeCustomer.photo);
          sendTrigger('Merge', activeCustomer.photo);
          await editCustomerPhoto(editId, '');
          window.location.reload();
        }
      } else {
        if (typeof editPhoto === 'object') {
          const filePhoto = await toBase64(editPhoto);
          const fileName = randomString() + '.' + (editPhoto.name ? editPhoto.name.split('.').pop() : 'jpg');
          await uploadPhoto(filePhoto, fileName);
          const dataTrigger = await sendTrigger('Add', fileName);
          if (dataTrigger.error === false) {
            if (activeCustomer.photo) {
              await sendTrigger('Delete', activeCustomer.photo);
            }
            sendTrigger('Merge', fileName);
            await editCustomerPhoto(editId, fileName);
            window.location.reload();
          } else {
            setEditPhoto(null);
            setIsLoading(false);
            alert('Cannot detect face. Please try another photo.');
          }
        } else {
          window.location.reload();
        }
      }
    } else {
      console.log('Error editing Customer');
    }
  }

  const deleteCustomer = async (deleteId) => {
    let data = {
      id: deleteId,
    };
    const fetchDeleteCustomer = await fetch(apiUrl + 'delete/customer', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify(data)
    });
    const dataDeleteCustomer = await fetchDeleteCustomer.json();
    return dataDeleteCustomer;
  }

  const sendTrigger = async (action, fileName)=> {
    let data = {};
    if (action === 'Merge') {
      data = {
        action: action,
        bucket_name: bucketName,
        bucket_path: bucketMerge
      }
    } else {
      data = {
        action: action,
        filename: fileName,
        bucket_name: bucketName,
        bucket_path: bucketFolder + '/' + fileName
      }
    }
    const fetchTrigger = await fetch(triggerUrl + (action !== 'Add' ? ('/' + action.toLowerCase()) : ''), {
      headers: {
        "Content-Type": "application/json",
        "Authorization": triggerAuth
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    const dataTrigger = await fetchTrigger.json();
    return dataTrigger;
  }

  const editCustomerPhoto = async (id, fileName)=> {
    const fetchCustomer = await fetch(apiUrl + 'edit/photo', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        id: id,
        photo: fileName
      })
    });
    const dataCustomer = await fetchCustomer.json();
    return dataCustomer;
  }

  const uploadPhoto = async (filePhoto, fileName) => {
    const data = { 
      fileName: fileName 
    };
    const fetchUpload = await fetch(apiUrl + 'file/upload', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    const dataUpload = await fetchUpload.json();
    if (dataUpload.status === 200) {
      const atob = require('atob');
      let base64 = filePhoto;
      base64 = base64.replace(/^data:image\/\w+;base64,/, '');
      base64 = base64.replace(/^data:application\/octet-stream;base64,/, '');
      const binary = atob(base64);
      const uint8 = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        uint8[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([uint8]);
      const fetchPut = await fetch(dataUpload.values, {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        method: "PUT",
        body: blob
      });
      return fetchPut;
    }
  }

  const handleChangeMenu = (e, menu) => {
    e.preventDefault();
    setActiveMenu(menu);
    setSelectedDate(moment().format('YYYY-MM-DD'));
    setSelectedSorting('Date');
    setShowDetailCustomer(false);
    setShowAddCustomer(false);
    setShowEditCustomer(false);
  }

  const handleSelectCustomer = (e, customerId) => {
    e.preventDefault();
    getCustomer(customerId);
    getTransactionList(customerId);
    setShowDetailCustomer(true);
    setShowAddCustomer(false);
    setShowEditCustomer(false);
  }

  const handleAddCustomer = (e) => {
    e.preventDefault();
    setActiveCustomer({});
    setShowDetailCustomer(false);
    setShowAddCustomer(true);
    setShowEditCustomer(false);
  }

  const handleEditCustomer = (e) => {
    e.preventDefault();
    console.log(moment(activeCustomer.dob).format('YYYY-MM-DD'));
    setEditId(activeCustomer.id);
    setEditName(activeCustomer.name);
    setEditPhone(activeCustomer.phone);
    setEditEmail(activeCustomer.email);
    setEditGender(activeCustomer.gender);
    setEditDob(moment(activeCustomer.dob).format('YYYY-MM-DD'));
    setEditAddress(activeCustomer.address);
    setEditPhoto(activeCustomer.link);
    setShowDetailCustomer(false);
    setShowAddCustomer(false);
    setShowEditCustomer(true);
  }

  return (
    <div id="page-home">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-lg-5">
                <div className="card mb-4">
                  <div className="card-header"><strong>Menu</strong></div>
                  <div className="card-body">
                    <div className="list-group">
                      <div className="list-group-item pointer" onClick={(e) => handleChangeMenu(e, 'Visit')}>
                        <a className="d-block text-dark" href="" style={{textDecoration: 'none'}}>{activeMenu === "Visit" ? <strong>Visits</strong> : "Visits"}</a>
                      </div>
                      <div className="list-group-item pointer" onClick={(e) => handleChangeMenu(e, 'Customer')}>
                        <a className="d-block text-dark" href="" style={{textDecoration: 'none'}}>{activeMenu === "Customer" ? <strong>Customers</strong> : "Customers"}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                {activeMenu === 'Visit' ? (
                  <div className={"card " + ((showAddCustomer || showDetailCustomer || showEditCustomer) ? 'm-hide' : '')}>
                    <div className="card-header"><strong>Visit List</strong></div>
                    <div className="card-body fix-height">
                      <div className="d-flex justify-content-between flex-wrap gap-2">
                        <input className="form-control" type="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}/>
                        <select className="form-select" value={selectedSorting} onChange={(e) => setSelectedSorting(e.target.value)}>
                          <option value="Date">Sort by Date</option>
                          <option value="Name">Sort by Name</option>
                        </select>
                      </div>
                      {visitList.length ? (
                        <div className="list-group mt-3">
                          {visitList.map(visit =>
                            <div className="list-group-item d-flex align-items-center gap-4 pointer" key={"visit-" + visit.id} onClick={(e) => handleSelectCustomer(e, visit.customer_id)}>
                              <img src={visit.photo ? visit.link : '/no-photo.png'} style={{display: 'block', width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%', border: '1px solid ' + (activeCustomer.id === visit.customer_id ? '#000' : '#fff') }}/>
                              <p className="mb-0">
                                {activeCustomer.id === visit.customer_id ? <strong>{visit.name}</strong> : <span>{visit.name}</span>}
                                <br/>
                                {moment(visit.time_arrive).format('dddd, DD MMMM YYYY')}
                                <br/>
                                {moment(visit.time_arrive).format('HH:mm')}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="mt-2">No result</p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={"card " + ((showAddCustomer || showDetailCustomer || showEditCustomer) ? 'm-hide' : '')}>
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <strong>Customer List</strong>
                      <a className="text-dark" href="" onClick={(e) => handleAddCustomer(e)} style={{textDecoration: 'none'}} title="Add"><i className="bi bi-plus-lg"></i></a>
                    </div>
                    <div className="card-body fix-height">
                      {customerList.length ? (
                        <div className="list-group">
                          {customerList.map(customer =>
                            <div className="list-group-item d-flex align-items-center gap-4" key={"customer-" + customer.id} onClick={(e) => handleSelectCustomer(e, customer.id)} style={{cursor: 'pointer'}}>
                              <img src={customer.photo ? customer.link : '/no-photo.png'} style={{display: 'block', width: '80px', height: '80px', objectFit: 'cover', borderRadius: '50%', border: '1px solid ' + (activeCustomer.id === customer.id ? '#000' : '#fff') }}/>
                              <p className="mb-0">
                                {activeCustomer.id === customer.id ? <strong>{customer.name}</strong> : <span>{customer.name}</span>}
                                <br/>
                                {customer.email}
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>No result</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {showDetailCustomer ? (
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <strong>Customer Information</strong>
                  <div className="text-right">
                    <a className="text-dark" href="" onClick={(e) => { e.preventDefault(); setShowDetailCustomer(false); }} title="Close"><i className="bi bi-x-lg"></i></a>
                    <a className="text-dark ms-3" href="" onClick={(e) => handleEditCustomer(e)} style={{textDecoration: 'none'}} title="Edit"><i className="bi bi-pencil"></i></a>
                  </div>
                </div>
                <div className="card-body fix-height">
                  <img src={activeCustomer.photo ? activeCustomer.link : '/no-photo.png'} style={{width: '320px', maxWidth: '100%', height: '320px', borderRadius: '20px', objectFit: 'cover'}}/>
                  <div className="row mt-4">
                    <label className="col-md-3">Name</label>
                    <div className="col-md-9 m-strong">{activeCustomer.name}</div>
                  </div>
                  {activeCustomer.phone ? (
                  <div className="row mt-2">
                    <label className="col-md-3">Phone</label>
                    <div className="col-md-9 m-strong">{activeCustomer.phone}</div>
                  </div>
                  ) : ''}
                  {activeCustomer.email ? (
                  <div className="row mt-2">
                    <label className="col-md-3">Email</label>
                    <div className="col-md-9 m-strong">{activeCustomer.email}</div>
                  </div>
                  ) : ''}
                  {activeCustomer.gender ? (
                  <div className="row mt-2">
                    <label className="col-md-3">Gender</label>
                    <div className="col-md-9 m-strong">{activeCustomer.gender}</div>
                  </div>
                  ) : ''}
                  {activeCustomer.dob ? (
                  <div className="row mt-2">
                    <label className="col-md-3">DOB</label>
                    <div className="col-md-9 m-strong">{moment(activeCustomer.dob).format('DD MMMM YYYY')}</div>
                  </div>
                  ) : ''}
                  {activeCustomer.address ? (
                  <div className="row mt-2">
                    <label className="col-md-3">Address</label>
                    <div className="col-md-9 m-strong">{activeCustomer.address}</div>
                  </div>
                  ) : ''}
                  {activeCustomer.last_visit ? (
                  <div className="row mt-2">
                    <label className="col-md-3">Last Visit</label>
                    <div className="col-md-9 m-strong">{moment(activeCustomer.last_visit).format('dddd, DD MMMM YYYY HH:mm')}</div>
                  </div>
                  ) : ''}
                  <div className="mt-4">
                    <p><strong>This Month Statistics</strong></p>
                  </div>
                  <div className="row mt-2">
                    <label className="col-md-3">Total Visit</label>
                    <div className="col-md-9 m-strong">{parseInt(activeCustomer.total_visit) > 0 ? activeCustomer.total_visit : 0}</div>
                  </div>
                  <div className="row mt-2">
                    <label className="col-md-3">Total Spent</label>
                    <div className="col-md-9 m-strong">{parseInt(activeCustomer.total_amount) > 0 ? currencyFormat(activeCustomer.total_amount) : 0}</div>
                  </div>
                  {transactionList.length > 0 ? (
                    <div className="mt-4">
                      <p><strong>This Month Transactions</strong></p>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Date</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionList.map((transaction, t) =>
                              <tr key={"transaction-" + transaction.id}>
                                <td>{t + 1}.</td>
                                <td>{moment(transaction.datetime).format('DD MMMM YYYY')}</td>
                                <td>{currencyFormat(transaction.amount)}</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : ''}
                </div>
              </div>
            ) : ''}
            {showAddCustomer ? (
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <strong>New Customer</strong>
                  {isLoading ? (
                    <Spinner className="spinner" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <div className="text-right">
                      <a className="text-dark" href="" onClick={(e) => { e.preventDefault(); setShowAddCustomer(false); }} title="Cancel"><i className="bi bi-x-lg"></i></a>
                      <a className="text-dark ms-3" href="" onClick={(e) => { e.preventDefault(); addCustomer(); }} title="Save"><i className="bi bi-floppy"></i></a>
                    </div>
                  )}
                </div>
                <div className="card-body fix-height">
                  <form onSubmit={(e) => { e.preventDefault(); addCustomer(); }}>
                    <input type="file" accept="image/*" ref={addPhotoRef} onChange={(e) => setAddPhoto(e.target.files[0])} style={{display: 'none'}}/>
                    {showCamera ? (
                      <div className="d-inline-block position-relative">
                        <img src="/layout.png" alt="Face" style={{pointerEvents: 'none', position: 'absolute', zIndex: 2, objectFit: 'cover', width: '100%'}}/>
                        <Webcam className="webcam" ref={webcamRef} width={320} height={320} style={{maxWidth: '100%', objectFit: 'cover'}} mirrored={true} screenshotFormat="image/jpeg" videoConstraints={{width: 720, height: 720, facingMode: "user"}}/>
                        <div className="btn-edit-list">
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); setShowCamera(false); }}><i className="bi bi-x-lg"></i></a>
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); capture('Add'); }}><i className="bi bi-record-circle"></i></a>
                        </div>
                      </div>
                    ) : (
                      <div className="d-inline-block position-relative">
                        <img src={addPhoto ? URL.createObjectURL(addPhoto) : '/no-photo.png'} style={{width: '320px', maxWidth: '100%', height: '320px', borderRadius: '20px', objectFit: 'cover'}}/>
                        <div className="btn-edit-list">
                          {addPhoto ? <a className="text-dark" href="" onClick={(e) => { e.preventDefault(); setAddPhoto(null); }}><i className="bi bi-trash"></i></a> : ''}
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); setShowCamera(true); }}><i className="bi bi-camera"></i></a>
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); addPhotoRef.current.click(); }}><i className="bi bi-folder"></i></a>
                        </div>
                      </div>
                    )}
                    <div className="row form-group align-items-center mt-4">
                      <label className="col-md-3">Name</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Phone</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={addPhone} onChange={(e) => setAddPhone(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Email</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={addEmail} onChange={(e) => setAddEmail(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Gender</label>
                      <div className="col-md-9">
                        <select className="form-select" value={addGender} onChange={(e) => setAddGender(e.target.value)}>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">DOB</label>
                      <div className="col-md-9"><input className="form-control" type="date" value={addDob} onChange={(e) => setAddDob(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Address</label>
                      <div className="col-md-9"><textarea className="form-control" value={addAddress} onChange={(e) => setAddAddress(e.target.value)}/></div>
                    </div>
                  </form>
                </div>
              </div>
            ) : ''}
            {showEditCustomer ? (
              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                  <strong>Edit Customer</strong>
                  {isLoading ? (
                    <Spinner className="spinner" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <div className="text-right">
                      <a className="text-dark" href="" onClick={(e) => { e.preventDefault(); setShowDetailCustomer(true); setShowEditCustomer(false); }} title="Cancel"><i className="bi bi-x-lg"></i></a>
                      <a className="text-dark ms-3" href="" onClick={(e) => { e.preventDefault(); editCustomer(); }} title="Save"><i className="bi bi-floppy"></i></a>
                    </div>
                  )}
                </div>
                <div className="card-body fix-height">
                  <form onSubmit={(e) => { e.preventDefault(); editCustomer(); }}>
                    <input type="file" accept="image/*" ref={editPhotoRef} onChange={(e) => setEditPhoto(e.target.files[0])} style={{display: 'none'}}/>
                    {showCamera ? (
                      <div className="d-inline-block position-relative">
                        <img src="/layout.png" alt="Face" style={{pointerEvents: 'none', position: 'absolute', zIndex: 2, objectFit: 'cover', width: '100%'}}/>
                        <Webcam className="webcam" ref={webcamRef} width={320} height={320} style={{maxWidth: '100%', objectFit: 'cover'}} mirrored={true} screenshotFormat="image/jpeg" videoConstraints={{width: 720, height: 720, facingMode: "user"}}/>
                        <div className="btn-edit-list">
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); setShowCamera(false); }}><i className="bi bi-x-lg"></i></a>
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); capture('Edit'); }}><i className="bi bi-record-circle"></i></a>
                        </div>
                      </div>
                    ) : (
                      <div className="d-inline-block position-relative">
                        <img src={editPhoto ? (typeof editPhoto === 'string' ? editPhoto : URL.createObjectURL(editPhoto)) : '/no-photo.png'} style={{width: '320px', maxWidth: '100%', height: '320px', borderRadius: '20px', objectFit: 'cover'}}/>
                        <div className="btn-edit-list">
                          {editPhoto ? <a className="text-dark" href="" onClick={(e) => { e.preventDefault(); setEditPhoto(null); }}><i className="bi bi-trash"></i></a> : ''}
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); setShowCamera(true); }}><i className="bi bi-camera"></i></a>
                          <a className="text-dark ms-2" href="" onClick={(e) => { e.preventDefault(); editPhotoRef.current.click(); }}><i className="bi bi-folder"></i></a>
                        </div>
                      </div>
                    )}
                    <div className="row form-group align-items-center mt-4">
                      <label className="col-md-3">Name</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)} required/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Phone</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={editPhone} onChange={(e) => setEditPhone(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Email</label>
                      <div className="col-md-9"><input className="form-control" type="text" value={editEmail} onChange={(e) => setEditEmail(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Gender</label>
                      <div className="col-md-9">
                        <select className="form-select" value={editGender} onChange={(e) => setEditGender(e.target.value)}>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">DOB</label>
                      <div className="col-md-9"><input className="form-control" type="date" value={editDob} onChange={(e) => setEditDob(e.target.value)}/></div>
                    </div>
                    <div className="row form-group align-items-center mt-2">
                      <label className="col-md-3">Address</label>
                      <div className="col-md-9"><textarea className="form-control" value={editAddress} onChange={(e) => setEditAddress(e.target.value)}/></div>
                    </div>
                  </form>
                </div>
              </div>
            ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
